import styled from 'styled-components';
import Spin from 'antd/lib/spin/index';

const Loader = () => {
  return (
    <Container>
      <Spin size="large" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
`;

export default Loader;
