export const FetchStatus = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  PENDING: 'pending'
};

export const UserRoles = {
  ADMIN: 'Admin',
  MEDIA_BUYER: 'media_buyer'
};
