export const ADD_CROSSROAD_ERRORS = 'ADD_CROSSROAD_ERRORS';
export const REMOVE_CROSSROAD_ERRORS = 'REMOVE_CROSSROAD_ERRORS';
export const SET_FETCH_CROSS_STATUS = 'SET_FETCH_CROSS_STATUS';
export const RECEIVE_CROSSROAD_DATA = 'RECEIVE_CROSSROAD_DATA';
export const RECEIVE_CROSSROAD_BY_CAMPAIGN_ID_DATA =
  'RECEIVE_CROSSROAD_BY_CAMPAIGN_ID_DATA';
export const RECEIVE_OVERVIEW_CROSSROAD_DATA =
  'RECEIVE_OVERVIEW_CROSSROAD_DATA';
export const SET_FETCH_CROSS_CAMPAIGN_ID_STATUS =
  'SET_FETCH_CROSS_CAMPAIGN_ID_STATUS';
export const SET_FETCH_CROSS_OVERVIEW_STATUS =
  'SET_FETCH_CROSS_OVERVIEW_STATUS';
