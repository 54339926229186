import {SET_MEDIA_BUYER_ID} from './constants';

// eslint-disable-next-line import/prefer-default-export
export const setMediaBuyerId = (data) => {
  const parsedData =
    data !== 'admin'
      ? data
          .split(' ')
          .map((item) => item[0])
          .join('')
          .toLowerCase()
      : data;
  return {
    type: SET_MEDIA_BUYER_ID,
    payload: parsedData
  };
};
