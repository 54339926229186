import axios from 'axios';
import {FetchStatus} from '../../constants/enums';
import {
  ADD_USERS_ERRORS,
  REMOVE_USERS_ERRORS,
  SET_FETCH_USERS_FOR_MENU_STATUS,
  RECEIVE_USERS_FOR_MENU_DATA,
  SET_FETCH_USER_ACCOUNTS_STATUS,
  RECEIVE_USER_ACCOUNTS_DATA
} from './constants';

const baseURL = process.env.REACT_APP_BACKEND_URL;

// Errors
export const addUsersErrors = (data) => {
  return {
    type: ADD_USERS_ERRORS,
    payload: data
  };
};

export const removeUsersErrors = () => {
  return {type: REMOVE_USERS_ERRORS};
};

export const setFetchUsersForMenuStatus = (status) => {
  return {
    type: SET_FETCH_USERS_FOR_MENU_STATUS,
    payload: status
  };
};

const receiveUsersForMenuData = (data) => {
  return {
    type: RECEIVE_USERS_FOR_MENU_DATA,
    payload: data
  };
};

export const setFetchUserAccountsStatus = (status) => {
  return {
    type: SET_FETCH_USER_ACCOUNTS_STATUS,
    payload: status
  };
};

const receiveUserAccountsData = (data) => {
  return {
    type: RECEIVE_USER_ACCOUNTS_DATA,
    payload: data
  };
};

export const fetchUsersForMenu =
  (token, permissions) => async (dispatch, getState) => {
    permissions = permissions.toLowerCase();

    if (permissions !== 'admin') return;
    if (getState().users.fetchUsersForMenuStatus !== null) return;

    dispatch(setFetchUsersForMenuStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/users`;
    try {
      const {data} = await axios.get(url, {
        user: {permissions},
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const result = data
        .filter((u) => {
          return !u.token;
        })
        .map((dataFromResponse) => {
          const processName = (name) => {
            if (name !== 'admin') {
              return name
                .split(' ')
                .map((item) => item[0])
                .join('')
                .toLowerCase();
            }
            return 'admin';
          };

          return {
            // eslint-disable-next-line max-len
            name:
              dataFromResponse.nickname.charAt(0).toUpperCase() +
              dataFromResponse.nickname.slice(1),
            mediaId: processName(dataFromResponse.nickname)
          };
        });

      dispatch(setFetchUsersForMenuStatus(FetchStatus.SUCCESS));
      dispatch(receiveUsersForMenuData(result));
    } catch ({response}) {
      dispatch(addUsersErrors(response));
      dispatch(setFetchUsersForMenuStatus(FetchStatus.FAILURE));
    }
  };

export const fetchUserAccounts = (token, permissions) => async (dispatch) => {
  permissions = permissions.toLowerCase();

  if (permissions !== 'admin') return;

  dispatch(setFetchUserAccountsStatus(FetchStatus.PENDING));
  const url = `${baseURL}api/users`;
  try {
    const {data} = await axios.get(url, {
      user: {permissions},
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    // filter elligible accounts
    const eligibleAccounts = data.filter((account) => account.token !== null);

    dispatch(setFetchUserAccountsStatus(FetchStatus.SUCCESS));
    dispatch(receiveUserAccountsData(eligibleAccounts));
  } catch ({response}) {
    dispatch(addUsersErrors(response));
    dispatch(setFetchUserAccountsStatus(FetchStatus.FAILURE));
  }
};
