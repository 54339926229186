import {
  ADD_CROSSROAD_ERRORS,
  REMOVE_CROSSROAD_ERRORS,
  SET_FETCH_CROSS_STATUS,
  RECEIVE_CROSSROAD_DATA,
  RECEIVE_CROSSROAD_BY_CAMPAIGN_ID_DATA,
  RECEIVE_OVERVIEW_CROSSROAD_DATA,
  SET_FETCH_CROSS_OVERVIEW_STATUS,
  SET_FETCH_CROSS_CAMPAIGN_ID_STATUS
} from './constants';

const initialState = {
  errors: [],
  fetchCrossroadStatus: null,
  fetchCrossroadOverviewStatus: null,
  fetchCrossroadCampaignIdStatus: null,
  crossroadDataByMediaBuyer: [],
  crossroadByCampaignIdData: [],
  crossroadOverviewData: []
};

const crossroadReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CROSSROAD_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case REMOVE_CROSSROAD_ERRORS: {
      return {...state, errors: []};
    }
    case SET_FETCH_CROSS_STATUS: {
      return {
        ...state,
        fetchCrossroadStatus: action.payload
      };
    }
    case SET_FETCH_CROSS_OVERVIEW_STATUS: {
      return {
        ...state,
        fetchCrossroadOverviewStatus: action.payload
      };
    }
    case SET_FETCH_CROSS_CAMPAIGN_ID_STATUS: {
      return {
        ...state,
        fetchCrossroadCampaignIdStatus: action.payload
      };
    }
    case RECEIVE_CROSSROAD_DATA: {
      return {
        ...state,
        crossroadDataByMediaBuyer: action.payload
      };
    }
    case RECEIVE_CROSSROAD_BY_CAMPAIGN_ID_DATA: {
      return {
        ...state,
        crossroadByCampaignIdData: action.payload
      };
    }
    case RECEIVE_OVERVIEW_CROSSROAD_DATA: {
      return {
        ...state,
        crossroadOverviewData: action.payload
      };
    }

    default:
      return state;
  }
};

export default crossroadReducer;
