import {combineReducers} from 'redux';
import appReducer from './app/reducer';
import usersReducer from './users/reducer';
import authReducer from './auth/reducer';
import crossroadReducer from './crossroad/reducer';
import facebookReducer from './facebook/reducer';

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  crossroad: crossroadReducer,
  facebook: facebookReducer,
  users: usersReducer
});
