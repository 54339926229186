import axios from 'axios';
import {FetchStatus} from '../../constants/enums';
import {
  ADD_AUTH_ERRORS,
  REMOVE_AUTH_ERRORS,
  SET_FETCH_AUTH_STATUS,
  RECEIVE_AUTH_DATA,
  SET_ADD_FACEBOOK_ACCOUNT_STATUS,
  RECEIVE_FACEBOOK_ACCOUNT_DATA
} from './constants';

const baseURL = process.env.REACT_APP_BACKEND_URL;

// Errors
export const addAuthErrors = (data) => {
  return {
    type: ADD_AUTH_ERRORS,
    payload: data
  };
};

export const removeAuthErrors = () => {
  return {type: REMOVE_AUTH_ERRORS};
};

export const setFetchAuthStatus = (status) => {
  return {
    type: SET_FETCH_AUTH_STATUS,
    payload: status
  };
};

export const setAddFacebookAccountStatus = (status) => {
  return {
    type: SET_ADD_FACEBOOK_ACCOUNT_STATUS,
    payload: status
  };
};

const receiveAuthData = (data) => {
  return {
    type: RECEIVE_AUTH_DATA,
    payload: data
  };
};

const receiveFacebookAccountData = (data) => {
  return {
    type: RECEIVE_FACEBOOK_ACCOUNT_DATA,
    payload: data
  };
};

export const fetchAuthData = (token, user) => async (dispatch, getState) => {
  if (getState().auth.fetchAuthStatus !== null) return;

  try {
    dispatch(setFetchAuthStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth/login`;

    const payload = {
      name: user.name,
      email: user.email,
      image_url: user.picture,
      nickname: user.nickname,
      sub: user.sub
    };
    const {data} = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch(setFetchAuthStatus(FetchStatus.SUCCESS));
    dispatch(receiveAuthData(data.data));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setFetchAuthStatus(FetchStatus.FAILURE));
  }
};

export const addFacebookAccount = (token, user) => async (dispatch) => {
  try {
    dispatch(setAddFacebookAccountStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth/add_facebook_account`;
    const {data} = await axios.post(url, user, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch(setAddFacebookAccountStatus(FetchStatus.SUCCESS));
    dispatch(receiveFacebookAccountData(data.data));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setAddFacebookAccountStatus(FetchStatus.FAILURE));
  }
};
